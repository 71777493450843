import React from 'react'
import { Upload, Icon, message, Row, Col, Spin, Modal, Card } from 'antd';
import axios from 'axios'
import _ from 'underscore'

function scaleValue(x) {
  const min_val = 4.5;
  const max_val = 6.3;
  if(x < min_val){ x = min_val }
  if(x > max_val) { x = max_val }

  const scaled_val = (x-min_val)/(max_val-min_val) * 10;

  return scaled_val;
}

function getBase64(img, imgCb, predCb, scoreCb) {
  const reader = new FileReader();
  reader.addEventListener('load', () => imgCb(reader.result));
  reader.onload = function() {
    let tmp = reader.result
    tmp = tmp.split("base64,");
    tmp = tmp[1];

    axios({
      method: 'post',
      url: 'https://he95pwxbr8.execute-api.us-east-1.amazonaws.com/dev/classify',
      data: tmp
    })
    .then(function (res) {
      console.log('cc', res.data);

      const newRes = _.map(res.data.data, function(val) {
        const tmp = {'class': val[0], 'prob': parseFloat(val[1]).toFixed(3)};
        return tmp;
      });

      predCb(newRes);
    })
    .catch(function (err) {
      console.log('aa', err);
    })

    axios({
      method: 'post',
      url: 'https://he95pwxbr8.execute-api.us-east-1.amazonaws.com/dev/score',
      data: tmp
    })
    .then(function (res) {
      console.log('sc1', res.data);

      scoreCb(res.data.data);
    })
    .catch(function (err) {
      console.log('sa1', err);
    })

    console.log('sb1', reader.result);
  };
  reader.readAsDataURL(img);
}

class Uploader extends React.Component {
  state = {
    loading: false,
    showSpinner: false,
    imgData: '',
    predData: [],
    scoreData: '',
    modalVisible: false,
    modalScore: 5,
    modalImg: ''
  }

  showModal = (e) => {
    console.log(e);

    this.setState({
      modalVisible: true
    });

    if (e == 0) {
      this.setState({
        modalImg: 'abstract_blurry.jpg',
        modalScore: "1.2/10"
      })
    } else if (e == 1) {
      this.setState({
        modalImg: 'blurry_shoes.jpg',
        modalScore: '2.0/10'
      })
    } else if (e == 2) {
      this.setState({
        modalImg: 'boys.jpg',
        modalScore: "7.3/10"
      })
    } else if (e == 3) {
      this.setState({
        modalImg: 'boy_facepaint.jpg',
        modalScore: "7.6/10"
      })
    } else {
      this.setState({
        modalImg: 'boy_facepaint.jpg',
        modalScore: "7.6/10"
      })
    }
  }

  handleCancel = (e) => {
    console.log(e);

    this.setState({
      modalVisible: false
    });
  }

  beforeUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
    }
    const isSmall = file.size / 1024 / 1024 < 5;
    if (!isSmall) {
      message.error('Image must smaller than 5MB!');
    }
    return isJPG && isSmall;
  }

  handleChange = (info) => {
    const status = info.file.status;

    if (status !== 'uploading') {
      console.log('A', info.file, info.fileList);
      this.setState({
        loading: true,
        showSpinner: true,
        imgData: '',
        predData: [],
        scoreData: ''
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} uploaded.`);

      getBase64(info.file.originFileObj,
        imgData => this.setState({
          imgData: imgData,
          loading: false,
          showSpinner: true
        }),
        predData => this.setState({
          predData: predData,
          loading: false,
          //showSpinner: false
        }),
        scoreData => this.setState({
          scoreData: scoreData,
          showSpinner: false
        })
      );

    } else if (status === 'error') {
      message.success(`${info.file.name} upload finished.`);

      getBase64(info.file.originFileObj,
        imgData => this.setState({
          imgData: imgData,
          loading: false,
          showSpinner: true
        }),
        predData => this.setState({
          predData: predData,
          loading: false,
          //showSpinner: false
        }),
        scoreData => this.setState({
          scoreData: scoreData,
          showSpinner: false
        })
      );
    }
  }

  render() {
    const imgData = this.state.imgData;
    const predData = this.state.predData;
    const scoreData = this.state.scoreData;
    const spinnerData = this.state.showSpinner;
    let visible = this.state.modalVisible;

    const uploadButton = (
      <div>
        <Icon style={{ fontSize: 48 }} type={this.state.loading ? 'loading' : 'plus'} />
        <p style={{ fontSize: 18, paddingTop: 24 }} className="ant-upload-text">Click to select photo or drag one here.<br/>JPEGs (.jpg) less than 5MB only.</p>
      </div>
    );

    const loadingIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;

    const spinner = (
      <div className="spinner">
        <Spin indicator={loadingIcon} style={{ verticalAlign: 'middle' }} />
        <br/>
        <div style={{ paddingTop: 24, fontSize: 24 }}>Loading ...</div>
      </div>
    )

    let predInfo;

    const displayScore = scaleValue(parseFloat(scoreData.mean)).toFixed(1);
    const tags = _.chain(predData).filter(function(item) {
      return item.prob > 0.05;
    }).sortBy('prob').reverse().pluck('class').value();

    const gridStyle = {
      width: '25%',
      textAlign: 'center',
    };

    if(isNaN(displayScore)) {
      predInfo = (
        <div>
          <h1 style={{ fontSize: 76, fontWeight: 900, lineHeight: 1.15, marginBottom: 4 }}>How awesome are your photos?</h1>
          <h2 style={{ fontSize: 36, marginBottom: -4, lineHeight: 1.15 }}>We trained an <a href="https://arxiv.org/abs/1709.05424" target="_blank" rel="noopener noreferrer">artifical intelligence</a> to see the beauty of photos in the same way as you do. Try it out!</h2>
          <br/>

          <Row type="flex" justify="center" align="middle" gutter={{ xs: 4, sm: 8, md: 8, lg: 16 }}>
            <Col span={6}>
              <img alt="img" width="100%" onClick={()=>this.showModal(0)} src="abstract_blurry.jpg" />
            </Col>
            <Col span={6}>
              <img alt="img" width="100%" onClick={()=>this.showModal(1)} src="blurry_shoes.jpg" />
            </Col>
            <Col span={6}>
              <img alt="img" width="100%" onClick={()=>this.showModal(2)} src="boys.jpg" />
            </Col>
            <Col span={6}>
              <img alt="img" width="100%" onClick={()=>this.showModal(3)} src="boy_facepaint.jpg" />
            </Col>
          </Row>

          <br/>

          <h4 style={{ fontSize: 16, lineHeight: 0.8 }}>* Images are deleted after results are displayed.</h4>

          <Modal
            title={"Aesthetics Score: " + this.state.modalScore }
            visible={this.state.modalVisible}
            onCancel={this.handleCancel}
            onOk={this.handleCancel}
            footer={null}
            width={348}
          >
            <p><img alt="img" width="300" src={ this.state.modalImg } /></p>
          </Modal>
        </div>
      )
    } else {
      predInfo = (
        <div>
          <h1 style={{ fontSize: 76, fontWeight: 900, textAlign: 'center', lineHeight: 1.3 }}>Score: {displayScore}/10</h1>
          <h2 style={{ fontSize: 42, marginBottom: -6, lineHeight: 1.3 }}>Image may contain:</h2>
          <p style={{ fontSize: 36 }}>{tags.join(', ').replace(/_/g, ' ')}</p>
        </div>
      )
    }

    const notPred = '<div>{ tags.map(function(val){return <p>{val}</p>;}) }</div>'

    return (
      <div>
        <Row type="flex" justify="space-between" align="middle" gutter={{ xs: 16, sm: 24, md: 64, lg: 128 }}>
          <Col className="upload-col" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Upload
              name="image"
              listType="picture-card"
              className="img-uploader"
              showUploadList={false}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
              accept={"image/jpeg"}
              multiple={false}
            >
              { imgData ? <img className="uploaded" alt="" src={imgData} /> : uploadButton }
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            { spinnerData ?  spinner : predInfo }
          </Col>
        </Row>
      </div>
    )
  }
}

export default Uploader
