import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Layout, Menu } from 'antd'
const { Header, Content, Footer } = Layout

class BaseLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = { current: '/' }

    //console.log('al1', this.props.location);
  }

  handleClick = (e) => {
    console.log('click ', e);
    console.log('tmp', this.props.location);
    this.setState({
      current: e.key,
    });
  }

  render() {
    return (
      <Layout style={{height:"100vh"}} className="layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[ this.props.location ]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="/">
              <Link to="/"><span className="red-square"><span style={{ color: 'white' }}>LUNEAN</span></span></Link>
            </Menu.Item>
            <Menu.Item key="/about/">
              <Link to="/about/">About</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ paddingTop: "88px" }}>
          <div className="mainContent">
            {this.props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <a href="https://lunean.com">Lunean</a> :: <a href="mailto:info@lunean.com">Contact</a>
        </Footer>
      </Layout>
    )
  }
}

export default BaseLayout

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

/*
<div className="logo" style={{
  width: '120px',
  height: '31px',
  background: 'rgba(255,255,255,.2)',
  margin: '16px 24px 16px 0',
  float: 'left'
}}/>
*/

/*
            <Menu.Item key="/markdown/" onClick={this.handleClick} selectedKeys={[this.state.current]}>
              <Link to="/markdown/">Markdown</Link>
            </Menu.Item>
            <Menu.Item key="/tensorflow/">
              <Link to="/tensorflow/">Tensorflow</Link>
            </Menu.Item>
 */