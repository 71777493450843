import React from 'react'

import SiteLayout from '../components/site_layout'
import Uploader from '../components/uploader'

const IndexPage = ({ data }) => (
  <SiteLayout location="/">
    <Uploader/>
  </SiteLayout>
)

export default IndexPage

