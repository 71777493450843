import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import BaseLayout from './base_site_layout'

import './easytofindfile.css'

const SiteLayout = ({ children, data, location }) => (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'How awesome are your photos? We trained an artifical intelligence to see the beauty of photos in the same way as you do and score your pictures on a scale from 1-10. ' },
              { name: 'keywords', content: 'aesthetics pictures automatic curation image lunean artificial intelligence beauty photography score' },
              { name: 'google-site-verification', content: 'VAGpLrwLLA8WizqfUum5tXY5kjLTz7OD0d_ST5JzxSo' }
            ]}
          >
            <html lang="en"/>
          </Helmet>

          <BaseLayout location={location}>{children}</BaseLayout>
        </>
      )}
    />
)

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default SiteLayout
